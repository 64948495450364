<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';

import { inject } from 'vue';

// Components
import AddressForm from '@/Components/Address/AddInForm.vue';
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
import Form from '@/Components/Location/Form.vue';
// Icons
import saveIcon from '@i/save-white.svg';

const route = inject('route');
const props = defineProps({
    location: Object,
    claOptions: Object,
});

const form = useForm({
    ...props.location,
    cost_center: '',
    ...props.location.address,
});

const sendForm = () => {
    form.put(route('location.locations.update', { location: props.location.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <FormSingleSettingBar :title="$t('Contact Details')">
        <FormKit type="form" id="location-update-form" @submit="sendForm" :actions="false">
            <div class="grid gap-x-8 md:grid-cols-2">
                <Form :form="form" :claOptions="claOptions" />
            </div>
            <AddressForm :parentForm="form" />
            <div class="flex items-center justify-end w-full">
                <div class="">
                    <Button
                        type="button"
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                        :text="$t('Save')"
                        :icon="saveIcon"
                        @click="sendForm"
                    />
                </div>
            </div>
        </FormKit>
    </FormSingleSettingBar>
</template>
